import React, { useState } from 'react'
import styled from 'styled-components'
import { useAtom } from 'jotai'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import FormikErrorFocus from 'formik-error-focus'
import InputMask from 'react-input-mask'
import CpfCnpj from '@react-br-forms/cpf-cnpj-mask'
import { isDate, parse, subYears } from 'date-fns/esm'
import { cpf as CpfValidator } from 'cpf-cnpj-validator'

import legacyApi from '../../services/api/legacyApi'
import { loginUser } from '../../services/utils/user'
import { userData } from '../../services/state/appState'
import { formatCelNumber, formatDate } from '../../services/utils/transforms'
import { validateName } from '../../services/utils/validation'

import mixins from '../../styles/mixins'
import theme from '../../styles/theme'

import { Link, useHistory, useLocation } from 'react-router-dom'
import GlobalButton from '../common/GlobalButton'

const { colors } = theme

const StyledForm = styled(Form)`
  ${mixins.formStyles}
`

Yup.setLocale({
  mixed: {
    notType: 'Insira um valor válido',
  },
})

const SignupForm = props => {
  const [, setAtomUserData] = useAtom(userData)
  const [userCpfInUse, setUserCpfInUse] = useState(false)
  const [userAlreadySignedError, setUserAlreadySignedError] = useState(false)
  const [signupRequestError, setSignupRequestError] = useState(false)

  const history = useHistory()

  function parseDateString(value, originalValue) {
    return isDate(originalValue) ? originalValue : parse(originalValue, 'dd/MM/yyyy', new Date())
  }

  function validateEmailConfirmation(value, prev) {
    let error
    if (prev !== value) {
      error = 'Digite o mesmo email do campo acima'
    }
    return error
  }

  function validatePasswordConfirmation(value, prev) {
    let error
    if (prev !== value) {
      error = 'Digite a mesma senha do campo acima'
    }
    return error
  }

  let query = new URLSearchParams(useLocation().search)

  return (
    <Formik
      initialValues={{
        nome: '',
        email: query.has('mail') ? query.get('mail') : '',
        email_confirmation: '',
        cpf: query.has('cpf') ? query.get('cpf') : '',
        senhapass: '',
        senhapass_confirmation: '',
        celular: '',
        data_nascimento: '',
        has_accepted_terms: false,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().email('Insira um email válido').required('Preencha este campo'),

        cpf: Yup.string()
          .required('Insira seu CPF')
          .matches(
            /([0-9]{2}[.]?[0-9]{3}[.]?[0-9]{3}[/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[.]?[0-9]{3}[.]?[0-9]{3}[-]?[0-9]{2})/,
            'Insira um CPF válido.'
          )
          .test('cpf-valido', 'Insira um CPF válido', value => {
            return CpfValidator.isValid(value)
          }),

        senhapass: Yup.string()
          .required('Preencha este campo')
          .matches(/^(?=.*[a-zA-Z]).{6,}$/, 'Deve ter ao menos 6 caracteres entre letras e números'),

        celular: Yup.string()
          .required('Preencha este campo')
          .matches(/^\(?0?[1-9]{2}\)?[- ]?9[0-9]{4}[- ]?[0-9]{4}$/, 'Insira um número de celular válido'),

        data_nascimento: Yup.date('Insira uma data válida')
          .transform(parseDateString)
          .max(subYears(new Date(), 13), 'Você precisa ter mais que 13 anos de idade para criar uma conta.')
          .required('Preencha este campo'),
        has_accepted_terms: Yup.bool().oneOf([true], 'Você deve aceitar os termos e condições'),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        const submitUserData = async () => {
          return legacyApi
            .post('', {
              nome: values.nome,
              email: values.email,
              senhapass: values.senhapass,
              celular: formatCelNumber(values.celular),
              data_nascimento: formatDate(values.data_nascimento),
              cpf: values.cpf,
              op: 'benfeitorInsert',
              has_accepted_terms: values.has_accepted_terms,
            })
            .then(async response => {
              const data = response.data

              if (data.sucess) {
                loginUser(data.sucess, false)
                setAtomUserData(data.sucess)
              } else if (!data.sucess && data.error === 'CPF já está cadastrado no sistema.') {
                setUserCpfInUse(true)
              } else {
                setUserAlreadySignedError(true)
              }
              if (props.redirectsToPaymentPage) {
                history.push('/pagamento?active-step=payment-method')
              }
            })
            .catch(() => {
              setSignupRequestError(true)
            })
        }

        await submitUserData()
        setSubmitting(false)
      }}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
        <StyledForm onSubmit={handleSubmit}>
          <div className='form-control-container'>
            <Field
              type='email'
              inputMode='email'
              autoComplete='email'
              name='email'
              placeholder='Endereço de email'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
            />
            {errors.email && touched.email && <div className='validation-error'>{errors.email}</div>}
          </div>
          <div className='form-control-container'>
            <Field
              type='email'
              inputMode='email'
              name='email_confirmation'
              placeholder='Confirme o email'
              onChange={handleChange}
              onBlur={handleBlur}
              validate={() => validateEmailConfirmation(values.email_confirmation, values.email)}
            />
            {errors.email_confirmation && touched.email_confirmation && (
              <div className='validation-error'>{errors.email_confirmation}</div>
            )}
          </div>
          <div className='form-control-container'>
            <CpfCnpj
              type='tel'
              maxLength='14'
              name='cpf'
              placeholder='CPF'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.cpf}
            />
            {errors.cpf && touched.cpf && <div className='validation-error'>{errors.cpf}</div>}
          </div>
          <div className='form-control-container'>
            <Field
              type='text'
              name='nome'
              placeholder='Nome completo'
              onChange={handleChange}
              onBlur={handleBlur}
              validate={validateName}
              value={values.nome}
            />
            {errors.nome && touched.nome && <div className='validation-error'>{errors.nome}</div>}
          </div>
          <div className='form-control-container'>
            <InputMask
              type='tel'
              name='celular'
              placeholder='Telefone Celular'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.celular}
              mask='(99)99999-9999'
            />
            {errors.celular && touched.celular && <div className='validation-error'>{errors.celular}</div>}
          </div>
          <div className='form-control-container'>
            <Field
              type='password'
              autoComplete='new-password'
              name='senhapass'
              placeholder='Nova senha'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.senhapass}
            />
            {errors.senhapass && touched.senhapass && <div className='validation-error'>{errors.senhapass}</div>}
          </div>
          <div className='form-control-container'>
            <Field
              type='password'
              name='senhapass_confirmation'
              placeholder='Confirme sua senha'
              onChange={handleChange}
              onBlur={handleBlur}
              validate={() => validatePasswordConfirmation(values.senhapass_confirmation, values.senhapass)}
            />
            {errors.senhapass_confirmation && touched.senhapass_confirmation && (
              <div className='validation-error'>{errors.senhapass_confirmation}</div>
            )}
          </div>
          <div className='form-control-container'>
            <InputMask
              type='tel'
              name='data_nascimento'
              placeholder='Data de nascimento'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.data_nascimento}
              mask='99/99/9999'
            />
            {errors.data_nascimento && touched.data_nascimento && (
              <div className='validation-error'>{errors.data_nascimento}</div>
            )}
          </div>

          <div className='form-control-container-flex'>
            <input
              className='checkbox'
              type='checkbox'
              onClick={handleChange}
              checked={values.has_accepted_terms}
              name='has_accepted_terms'
              id='has_accepted_terms'
            />
            <label className='remain-connected' htmlFor='has_accepted_terms'>
              Aceito os{' '}
              <a target='_blank' href='/termos.pdf'>
                termos e condições
              </a>
            </label>

            {errors.has_accepted_terms && touched.has_accepted_terms && (
              <div className='validation-error'>{errors.has_accepted_terms}</div>
            )}
          </div>

          <GlobalButton
            large={false}
            dropShadow={true}
            background={colors.primary}
            labelColor={colors.light}
            border={colors.primary}
            hoverColor={colors.primary}
            buttonLabel='Realizar cadastro'
            type='submit'
          />

          {userAlreadySignedError ? (
            <div className='validation-error'>
              Esse usuário já está cadastrado. Para entrar na sua conta, clique em "Faça Login".
            </div>
          ) : null}

          {userCpfInUse ? <div className='validation-error'>CPF já está cadastrado no sistema.</div> : null}

          {signupRequestError ? (
            <div className='validation-error'>
              Ocorreu um erro ao entrar em contato com os servidores. Isso pode acontecer quando a conexão com a
              internet está indisponível ou irregular. Tente novamente mais tarde.
            </div>
          ) : null}

          {query.has('from') && query.get('from') === 'guest' ? null : (
            <div style={{ textAlign: 'center', marginTop: '32px' }}>
              <span>
                <p style={{ marginBottom: 12 }}>Já tem uma conta?</p>
                <Link to='/login'>
                  <GlobalButton
                    background={colors.light}
                    labelColor={colors.primary}
                    border={colors.primary}
                    hoverColor={colors.lightShade}
                    buttonLabel='Fazer login'
                  />
                </Link>
              </span>
            </div>
          )}

          <FormikErrorFocus offset={0} align={'top'} focusDelay={50} ease={'linear'} duration={500} />
        </StyledForm>
      )}
    </Formik>
  )
}

export default SignupForm
