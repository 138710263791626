import React, { useState } from 'react'
import styled from 'styled-components'
import { useAtom } from 'jotai'
import { Formik } from 'formik'
import * as Yup from 'yup'

import legacyApi from '../../services/api/legacyApi'
import { loginUser } from '../../services/utils/user'
import { userData } from '../../services/state/appState'

import mixins from '../../styles/mixins'
import theme from '../../styles/theme'

import { Link } from 'react-router-dom'
import GlobalButton from '../common/GlobalButton'
import FormikErrorFocus from 'formik-error-focus'

const { colors } = theme

const StyledForm = styled.form`
  ${mixins.formStyles}
`

const LoginForm = () => {
  const [loginRequestError, setLoginRequestError] = useState(false)
  const [loginError, setLoginError] = useState(false)
  const [remainConnected, setRemainConnected] = useState(false)
  const [, setAtomUserData] = useAtom(userData)

  return (
    <Formik
      initialValues={{
        login: '',
        senha: '',
        op: 'login',
        has_accepted_terms: true,
      }}
      validationSchema={Yup.object().shape({
        login: Yup.string().required('É necessário um endereço de email'),
        senha: Yup.string().required('Insira sua senha'),
        has_accepted_terms: Yup.bool().oneOf([true], 'Você deve aceitar os termos e condições'),
      })}
      onSubmit={(values, { setSubmitting }) => {
        legacyApi
          .post('', {
            login: values.login,
            senha: values.senha,
            op: 'login',
            has_accepted_terms: values.has_accepted_terms,
          })
          .then(response => {
            if (response.data) {
              loginUser(response.data, remainConnected)
              setAtomUserData(response.data)
            } else {
              setLoginError(true)
            }
          })
          .catch(() => {
            setLoginRequestError(true)
          })

        setSubmitting(false)
      }}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
        <StyledForm onSubmit={handleSubmit}>
          <div className='form-control-container'>
            <input
              type='email'
              inputMode='email'
              autoComplete='email'
              name='login'
              placeholder='Email'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.login}
            />
            {errors.login && touched.login && <div className='validation-error'>{errors.login}</div>}
          </div>
          <div className='form-control-container'>
            <input
              type='password'
              inputMode='password'
              autoComplete='password'
              name='senha'
              placeholder='Senha'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.senha}
            />
            {errors.senha && touched.senha && <div className='validation-error'>{errors.senha}</div>}
          </div>

          <div className='form-control-container-flex'>
            <input
              className='checkbox'
              type='checkbox'
              onClick={handleChange}
              checked={values.has_accepted_terms}
              name='has_accepted_terms'
              id='has_accepted_terms'
            />
            <label className='remain-connected' htmlFor='has_accepted_terms'>
              Aceito os{' '}
              <a target='_blank' href='/termos.pdf'>
                termos e condições
              </a>
            </label>

            {errors.has_accepted_terms && touched.has_accepted_terms && (
              <div className='validation-error'>{errors.has_accepted_terms}</div>
            )}
          </div>

          {loginError ? (
            <div className='validation-error'>Seu login ou senha estão incorretos. Tente novamente.</div>
          ) : null}

          {loginRequestError ? (
            <div className='validation-error'>
              Ocorreu um erro ao entrar em contato com os servidores. Isso pode acontecer quando a conexão com a
              internet está indisponível ou irregular. Tente novamente mais tarde.
            </div>
          ) : null}

          <div className='lower-section'>
            <div className='form-control-container-flex'>
              <input
                className='checkbox'
                type='checkbox'
                onClick={() => setRemainConnected(true)}
                id='remember-credentials'
              />
              <label className='remain-connected' htmlFor='remember-credentials'>
                Permanecer conectado
              </label>
            </div>
            <Link to='/recuperar-senha'>Esqueci minha senha</Link>
          </div>
          <GlobalButton
            large={false}
            dropShadow={true}
            background={colors.primary}
            labelColor={colors.light}
            border={colors.primary}
            hoverColor={colors.primary}
            buttonLabel='Fazer login'
            type='submit'
          />

          <div style={{ textAlign: 'center', marginTop: '32px' }}>
            <span>
              <p className='sub-text' style={{ marginBottom: 12 }}>
                Não tem uma conta?
              </p>
              <Link to={`/signup?mail=${values.login}&from=guest`} className='signup-link'>
                <GlobalButton
                  background={colors.light}
                  labelColor={colors.primary}
                  border={colors.primary}
                  hoverColor={colors.lightShade}
                  buttonLabel='Criar uma conta'
                  type='button'
                />
              </Link>
            </span>
          </div>

          <FormikErrorFocus offset={0} align={'top'} focusDelay={50} ease={'linear'} duration={500} />
        </StyledForm>
      )}
    </Formik>
  )
}

export default LoginForm
